<template>
  <div class="container">
    <div class="card border-0 shadow-none">
      <div class="card-body">
        <h4 class="fw-bold text-success mb-3">Forms Requiring Attention</h4>
        <table class="table">
          <thead>
            <tr>
              <th scope="col">Client</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="f in forms" :key="f.id">
              <th class="cursor-pointer" @click="view(f)" scope="row">
                {{ f.client ? f.client.name : "-" }}
              </th>
              <td class="cursor-pointer" @click="view(f)">
                {{ f.created_at | formatDate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      forms: [],
    };
  },
  methods: {
    view(f) {
      this.$router.push("/clients/" + f.client.id + "?form=" + f.id + "#forms");
    },
    fetchForms() {
      this.$axios
        .get(process.env.VUE_APP_API_URL + "/forms/requiring-attention")
        .then(({ data }) => {
          this.forms = data;
        });
    },
  },
  created() {
    this.fetchForms();
  },
  mounted() {
    this.$store.sidebarOpen = false;
  },
  filters: {
    formatDate: function (date) {
      return moment(date).format("LLL");
    },
  },
};
</script>

<style>
</style>